<template>
  <!-- <router-link to="/">Home</router-link> | -->
  <router-view />
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  color: #000;
}
</style>
