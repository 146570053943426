<template>
    <div class="app">
        <div class="container">
            <div class="status">សូមអរគុណ! | THANK YOU!</div>
            <br>
            <p>Our Sales team will get back to you shortly. For more information, reach us vai <span>081 976 999.</span>
            </p>
            <p>ក្រុមការងារផ្នែកលក់របស់យើងខ្ញុំនឹងទាក់ទងទៅលោកអ្នកក្នុងពេលឆាប់ៗនេះ។ សម្រាប់សេវាអតិថិជនសូមទំនាក់ទំនងតាមរយៈ
                <span>081 976 999.</span>
            </p>
            <div style="  padding-top: 10px; text-align: center;width: 100%;">
                <img :src="iconFacebook">
                <img :src="iconIG">
                <img :src="iconTelegram">
                <img :src="iconIN">
                <img :src="iconYoutube">
                <img :src="iconX">
                <img :src="iconTiktok">
            </div>
            <br>
            <button>យល់ព្រម / OK</button>
        </div>
    </div>
</template>
<script>
export default {
    data: function () {
        return {
            iconFacebook: require('@/assets/icon/facebook.png'),
            iconIG: require('@/assets/icon/instagram.png'),
            iconIN: require('@/assets/icon/linkedIN.png'),
            iconX: require('@/assets/icon/x.png'),
            iconTelegram: require('@/assets/icon/telegram.png'),
            iconTiktok: require('@/assets/icon/tiktok.png'),
            iconYoutube: require('@/assets/icon/youtube.png'),
        }
    },
    methods: {

    }
}
</script>

<style scoped>
img {
    margin: 3px;
    height: 30px;
    width: 30px;
    border-radius: 10px;

}

.app {
    background-color: #00B5AC;
    width: 100%;
    height: 100vh;
    padding: 120px 20px 20px 20px;
    margin: 0;
}

.container {
    width: 100%;
    padding: 20px;
    border-radius: 12px;
    height: auto;
    text-align: center;
    background-color: white;
}

span {
    color: #008881;
    font-family: "Khmer", sans-serif;
    font-weight: bold;
    font-size: 14px;
}

p {
    color: #008881;
    font-family: "Khmer", sans-serif;
    font-weight: 400;
    font-size: 13px;
    padding-top: 10px;
    padding-right: 20px;
    padding-left: 20px;
    text-align: center;
}

.status {
    background-color: #008881;
    color: #fff;
    font-family: "Khmer", sans-serif;
    font-weight: 100;
    font-size: 12px;
    padding-top: 11px;
    text-align: center;
    align-items: center;
    height: 36px;
    border-radius: 10px 10px 0 0;
    width: 100%;
}

button {
    height: 35px;
    width: 40%;
    background-color: #00B5AC;
    color: #fff;
    border: none;
    border-radius: 30px;
    font-family: "Khmer", sans-serif;
    font-weight: 400;
    font-size: 13px;
    text-align: center;
    padding-top: 4px;
    margin-top: 10px;
    margin-bottom: 20px;
}
</style>