<template>
  <div @submit.prevent="submit" class="app">
    <br>
    <div style="margin: 10px;">
      <div style=" height: 120px; ">
        <img :src="imageUrl">
      </div>
    </div>
    <div style=" text-align: center;">
      <p class="khmer-regular">ទំនាក់ទំនង</p>
      <p class="poppins-medium">GET IN TOUCH</p>
    </div>
    <div style="margin: 10px;">
      <div class="container">
        <p class="khmer-extralight">គោត្តនាម និងនាម | Full Name <span style="color: red;">*</span></p>
        <div class="input-container">
          <i style="padding-bottom: 0px;" class="material-icons"
            :class="{ icon_valid: !isValidUsername, icons: isValidUsername }">person</i>
          <input placeholder="Full Name" type="text" v-model="username"
            :class="{ invalid: !isValidUsername, valid: isValidUsername }" required>
          <span v-if="errorMessageUsername" class="error-message">Please input your full name!</span>
        </div>

        <p style="padding-top: 14px;" class="khmer-extralight">លេខទំនាក់ទំនង | Phone Number <span
            style="color: red;">*</span></p>
        <div class="input-container">
          <i style="padding-bottom: 0px;" class="material-icons"
            :class="{ icon_valid: !isValidPhone, icons: isValidPhone }">phone</i>
          <input placeholder="Phone Number" type="phone" v-model="phoneNumber"
            :class="{ invalid: !isValidPhone, valid: isValidPhone }" required>
          <span v-if="errorMessagePhoneNumber" class="error-message">Please input your phone number!</span>
        </div>
        <br>
        <div style="display: flex;">
          <input type='checkbox' class="checkbox" @click="toggleCheckbox">
          <p style="padding-left: 10px; padding-top: 6px; " class="khmer-extralight">លេខទូរស័ព្ទដែលភ្ជាប់ជាមួយ Telegram
          </p>
        </div>
        <br>
        <p class="khmer-extralight">អាសយដ្ឋាន | Address <span style="color: red;">*</span></p>
        <div style="margin-top: 4px;" class="select-container">
          <select v-model="city">
            <option v-for="(item, index) in citys" :key="index">{{ item }}</option>
          </select>
          <i class="material-icons icon-right">arrow_drop_down</i>
        </div>
        <span v-if="errorMessageCity" class="error-message">Please select your city!</span>
        <div style="margin-top: 14px;" class="select-container">
          <select v-model="district">
            <option v-for="(item, index) in districts" :key="index">{{ item
              }}</option>
          </select>
          <i class="material-icons icon-right">arrow_drop_down</i>
        </div>
        <span v-if="errorMessageDistrict" class="error-message">Please select your
          district!</span>
        <p style="margin-top: 22px;" class="khmer-extralight">កញ្ចប់ចាប់អារម្មណ៏ | Interested Package <span
            style="color: red;">*</span></p>
        <div style="display: flex; margin-top: 4px;">
          <div class="select-package" v-for="(item, index) in listPackage" :key="index"
            @click="selectItem(index, item.textEn)" :class="{ 'selected': selectedIndex === index }">
            <i class="material-icons icon-package">{{ item.icon }}</i>
            <p>{{ item.text }}</p>
            <p>{{ item.subtext }}</p>
            <p>{{ item.textEn }}</p>
          </div>
        </div>
        <span v-if="errorInteresPackage" class="error-message">Please select your interested package!</span>
        <p style="margin-top: 22px;" class="khmer-extralight">តម្រូវកាបន្ថែម | Comment </p>
        <input type="text" style="height: 60px; margin-top: 5px; border-radius: 10px; padding: 10px;" v-model="comment">
        <div style="text-align: center; margin-top: 15px;"> <button type="submit" @click="submit">ចុះឈ្មោះ / SIGN
            UP</button></div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
export default {

  name: 'HomeView',
  data: function () {
    return {
      imageUrl: require('@/assets/image/Logo.png'),
      username: '',
      phoneNumber: '',
      city: "Select City",
      citys: ['Select City', 'រាជាធានីភ្នំពេញ-Phnom Penh', 'កណ្តាល-kandal'],
      district: 'Select District',
      districts: ['Select District', 'ខណ្ឌទួលគក-Tul Kuk', 'ខណ្ឌច្បារអំពៅ-Chbar Ampov'],
      comment: '',
      isValidUsername: true,
      isValidPhone: true,
      isValidCity: true,
      isValidDistrict: true,
      isValidInterestedPackage: true,
      is_telegram: false,
      telegram_number: '',
      errorInteresPackage: '',
      errorMessageUsername: '',
      errorMessagePhoneNumber: '',
      errorMessageCity: '',
      errorMessageDistrict: '',
      interesPackage: '',
      selectedIndex: null,
      listPackage: [
        {
          text: 'កញ្ចប់',
          subtext: '​សម្រាប់គេហដ្ឋាន',
          textEn: 'Home Package',
          icon: 'groups',
        },
        {
          text: 'កញ្ចប់',
          subtext: '​សម្រាប់អាជីវកម្ម',
          textEn: 'Business Package',
          icon: 'home_work',
        },
      ],
    }
  },
  methods: {
    selectItem(index, text) {
      this.selectedIndex = index;
      this.interesPackage = text;
    },
    toggleCheckbox() {
      this.is_telegram = !this.is_telegram;
    },
    async postData() {
      try {
        const body = {
          'full_name': this.username,
          'phone_number': this.phoneNumber,
          'city': this.city,
          'district': this.district,
          'package': this.interesPackage,
          'comment': this.comment,
          'telegram_number': this.telegram_number,
          'is_telegram': this.is_telegram,
        }
        const responseData = await axios.post('http://127.0.0.1:8000/api/register', body)
        console.log('Data sent successfully:', responseData);
      } catch (error) {
        console.error('Error posting data:', error);
      }
    },

    submit() {
      if (this.is_telegram == true) {
        this.telegram_number = this.phoneNumber
      }
      if (this.username != '') {
        this.isValidUsername = true;
        this.errorMessageUsername = '';
      }
      else {
        this.isValidUsername = false;
        this.errorMessageUsername = "Please Input you full name!";
      }
      if (this.phoneNumber != '') {
        this.isValidPhone = true;
        this.errorMessagePhoneNumber = '';
      } else {
        this.isValidPhone = false;
        this.errorMessagePhoneNumber = "Please Input you Phone number!";
      }
      if (this.city != 'Select City') {
        this.errorMessageCity = '';
        this.isValidCity = true;
      } else {
        this.isValidCity = false;
        this.errorMessageCity = 'Please select your city!';
      }
      if (this.district != 'Select District') {
        this.isValidDistrict = true;
        this.errorMessageDistrict = '';
      } else {
        this.isValidDistrict = false;
        this.errorMessageDistrict = 'Please select your city!';
      }
      if (this.interesPackage != '') {
        this.isValidInterestedPackage = true;
        this.errorInteresPackage = '';
      } else {
        this.isValidInterestedPackage = false;
        this.errorInteresPackage = 'Please select your interest package!';
      }
      if (this.isValidCity == true && this.isValidDistrict == true && this.isValidInterestedPackage == true && this.isValidPhone == true && this.isValidUsername == true) {
        // this.postData();
        this.$router.push('/success');
      }
    },
  }
}

</script>
<style scoped>
.app {
  background-color: #00B5AC;
  width: 100%;
  height: auto;
  padding-bottom: 10px;
  margin: 0;

}


.invalid {
  border-color: red;
}

.valid {
  border: 1px solid #ccc;
}

.error-message {
  color: red;
  font-family: "Khmer", sans-serif;
  font-weight: 100;
  font-size: 12px;
  margin-top: 5px;
}

.icon_valid {
  position: absolute;
  left: 10px;
  top: 15px;
  font-size: 16px;
  transform: translateY(-50%);
  color: #888;
}

.icon-package {
  font-size: 45px;
  color: #00B5AC;
}

option {
  background-color: black;
  color: white;
}

button {
  height: 30px;
  font-family: "Khmer", sans-serif;
  font-weight: 100;
  font-size: 12px;
  padding-top: 4px;
  color: #fff;
  width: 50%;
  background-color: #00B5AC;
  border: none;
  border-radius: 20px;
}

.select-package {
  font-family: "Khmer", sans-serif;
  font-weight: 100;
  font-size: 12px;
  color: #00B5AC;
  width: 50%;
  height: 130px;
  margin-right: 10px;
  margin-left: 10px;
  text-align: center;
  padding: 10px;
  border-radius: 10px;
  background-color: white;

}

.selected {
  background-color: #03c0b75e;


}

.select-container {
  position: relative;
}

.icon-right {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  pointer-events: none;
  font-size: 20px;
  color: #00B5AC;

}



select {
  height: 30px;
  width: 100%;
  border: none;
  border-radius: 8px;
  font-family: "Khmer", sans-serif;
  font-weight: 100;
  font-size: 12px;
  color: #00B5AC;
  padding-left: 8px;
  padding-right: 2px;
  padding-top: 3px;
  appearance: none;
}

input[type="checkbox"]:checked {
  accent-color: #07968f;
}

.checkbox {
  width: 20px;
  height: 20px;

}

.textfield.focused .input {
  border: 1px solid red;
}

input::placeholder {
  color: #00B5AC;
  font-family: "Khmer", sans-serif;
  font-weight: 100;
  font-size: 12px;
}

.input-container {
  position: relative;
  text-align: start;
  margin-top: 2px;
}

.icons {
  position: absolute;
  left: 8px;
  top: 50%;
  font-size: 16px;
  transform: translateY(-50%);
  color: #888;
}

.input-container input {
  padding-left: 28px;
  height: 30px;
  border-radius: 8px;
  border: none;
}


input {
  width: 100%;
  border: none;
  font-family: "Khmer", sans-serif;
  font-weight: 100;
  font-size: 12px;
  border-color: #fff;
  padding-top: 3px;
  background-color: #fff;
  color: #00B5AC;
}

.container {
  width: 100%;
  height: auto;
  padding: 20px;
  border-radius: 12px;
  background-color: rgba(255, 255, 255, 0.877);
}

.poppins-medium {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: normal;
  font-weight: bold;
  color: #02FFF2;
  font-size: 20px;
  padding: 0;
}

.khmer-regular {
  font-family: "Khmer", sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 20px;
  color: #fff;

}

.khmer-extralight {
  font-family: "Khmer", sans-serif;
  font-weight: 100;
  font-size: 12px;
  color: #00B5AC;

}

p {
  margin-bottom: 0;
}

img {
  float: right;
  padding: 0px 20px 10px 10px;
  width: 120px
}
</style>
